import {InjectionToken} from '@angular/core'

export interface IEnvironment {
  production: boolean //,
  authServiceUrl: string // 'https://spb-common-api.internal.sparbankensyd.se/service',
  dataServiceUrl: string // 'https://ov.internal.sparbankensyd.se/api',
  domain: string // 'ov.internal.sparbankensyd.se'
  testMode: boolean
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('environment')
